import {configuration} from './Checkout.settings.service';

export const CartService = {
    
    lock( cart, voucher, jwt, onSuccess, onError)
    {
        let chain = '&cart=' + encodeURIComponent(JSON.stringify( cart ));
        chain       += '&v=' + encodeURIComponent(voucher) ;

        fetch(configuration.host + "iapi/ajax-lock-cart", {
            method: "POST",
            credentials: 'include',
            body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt,
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            console.log('Is logged in ', response);
            if( response.status === 'OK')
            {
                let x = Object.assign({}, response.cart);
                onSuccess(x);
            }                
            else
            {
                // to do - process non -lock
                onError(response.reason);
            }
        }).catch(function (err) {
            onError( 'Network error. Please refresh your browser and try again.' );
        });  

    }

}

