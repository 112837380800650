import React from 'react';
import { Link } from "react-router-dom";
import styles from './header.module.scss';
import {CheckoutControllerContext} from '../../contexts/CheckoutControllerContext';
import {CHECKOUT_STAGE_ACCOUNT, CHECKOUT_STAGE_INFO, CHECKOUT_STAGE_ATTENDEES, CHECKOUT_STAGE_PURCHASE_COMPLETE} from '../../contexts/CheckoutControllerContext';
import {configuration, settings} from '../../services/Checkout.settings.service';

const Header = ({layout}) => {


    const infoRequired = settings.mailactive || (!!settings.questionaires);

    const cls = infoRequired? '': 'threeSections'


    const {stage} = React.useContext(CheckoutControllerContext);
    return ( 
            <React.Fragment>
            {
                layout === 'checkout' &&
                <header className={styles.header}>
                    <div className="text-center mt-5">
                        <h1>Checkout</h1>
                        <ul id="progressbar" className={cls}>
                            <li className={stage >= CHECKOUT_STAGE_ACCOUNT?"active":""} id="account"><strong>Account</strong></li>
                            {
                                infoRequired && 
                                <li id="personal" className={stage >= CHECKOUT_STAGE_INFO?"active":""}><strong>Information & Preferences</strong></li>
                            }
                            <li id="payment" className={stage >= CHECKOUT_STAGE_ATTENDEES?"active":""}><strong>Booking and Payment</strong></li>
                            <li id="confirm" className={stage >= CHECKOUT_STAGE_PURCHASE_COMPLETE?"active":""}><strong>Complete</strong></li>
                        </ul>                  
                    </div>                  
                </header>
            } 
            </React.Fragment>
                     
     );
}
 
export default Header;