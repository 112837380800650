
import React, { Component } from 'react';
import { useEffect, useState } from "react";

import { useMemo } from "react";
import parse from 'html-react-parser';

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  CardElement, 
  Elements,
  ElementsConsumer
} from "@stripe/react-stripe-js";
import styles from './Card.module.scss';
import {configuration} from '../../services/Checkout.settings.service';
import {loadStripe} from '@stripe/stripe-js';
import { formatNumber } from '../../helpers/utils';
import ChargeError from './ChargeError';
import {CartService} from '../../services/cart.service';
import { JwtContext } from '../../contexts/JwtContext';

const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: 'inherit',
            color: "#424770",
            letterSpacing: "0.025em",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#dc3545"
          }
        }
      })
    );
  
    return options;
  };





const StripeCardForm = (props) => {
    const {elements, default_country, stripe, price, currency, terms, customer_terms,  disable,  cart, voucher, onIdle, onBusy, onComplete} = props;

    //const stripe =  useStripe();
    //const elements = useElements();
    const options = useOptions();
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [valid, setValid] = useState(false);
    const [numberValid, setNumberValid] = useState(false);
    const [expiryValid, setExpiryValid] = useState(false);
    const [cvcValid, setCVCValid] = useState(false);
    const [town, setTown] = useState('');
    const [zip, setZip] = useState('');
    const [state, setState] = useState(false);
    const [country, setCountry] = useState(default_country);
    const [error, setError] = useState('');  
    const [cards, setCards] = useState([]);  
    const [invoice, setInvoice] = React.useState( null );
    const {jwt} = React.useContext(JwtContext);
    const [lock, setLock] = React.useState(false);
    const [save, setSave] = useState(false);




    const onErrorClear = () => {
      setError('');
    }

    const decode = (a) => {
      
      var s = window.atob(a);
      return s;
    }


    const handleSubmit = async e => {
      e.preventDefault();

      if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          console.log('not loaded', stripe, elements)
          setError("Unable to load resources. This might be due to problems with your network. Please refresh your browser and try again. ")
          return;
      }
      if(lock)
      {
        console.error('Duplicate subbmission');
        return;
      }

      setLock(true);
      onBusy();

      const signature = cart.signature;


      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardNumberElement);
      console.log("CE", cardElement);

      // Use your card Element with other Stripe.js APIs
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {name: name, address: {line1: address, city: town, postal_code: zip, country: country}, email: window.email },            
      });

      if (error)
      {
        console.log('[error]', error);
        setError("Unable to process the card details: " + error.message );
        setLock(false);
        onIdle();
      } 
      else 
      {
        CartService.lock(cart, voucher, jwt,
          (rc)=>{
            console.log("returned cart", rc);
  
            if(signature !== rc.signature)
            {
              // something has changed - as the customer to double check
              setError("We have detected a change with your account. This may affect the price that you pay. Please re-check the cart. If you are happy to proceed, please try again");
              onIdle();
              return;
            }
  
            var chain = '';
            console.log('[PaymentMethod]', paymentMethod);
  
  
            chain = chain + '&id=' + paymentMethod.id;
            chain = chain + '&t=' + 1;
            chain = chain + '&fee=' + encodeURIComponent(price);
            chain = chain + '&currency=' + encodeURIComponent(currency);
            chain = chain + '&cart=' + encodeURIComponent(JSON.stringify(rc));
            chain = chain + '&voucher=' + encodeURIComponent(voucher); 
            chain = chain + '&invoice=' + encodeURIComponent(invoice); 
            chain = chain + '&save=' + encodeURIComponent(save); 
  
  
  
        
            fetch(configuration.host + "iapi/ajax-charge-customer-card", {
              body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
              method: "POST",
              credentials: 'include',
              headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest',
                    'Authorization': 'Bearer ' + jwt,
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    onComplete(true);
                    onIdle();
                    setLock(false);

                }
                else
                {
                  if(response.error_type === 'stripe')
                  {
                      var reason = '';
                      const status = response?.intent?.object?.intent_status || response?.intent?.object?.error_code;
                      setInvoice(response.record);


                      switch(status)
                      {
      
                        case 'amount_too_small':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'The transaction value is below the minimum threshold.';

                        }
                        break;

                        case 'requires_action':
                        {
                            onIdle();

                            const url = window !== window.top ? document.referrer : document.location.href;
                            const y = {message: 'rvRepostition', position: 'middle', data:[], height: document.getElementById('root').clientHeight };
                            window.parent.postMessage(JSON.stringify(y), url);    
                    

                            var secret = response.intent.object.client_secret;
                            stripe.confirmCardPayment(secret, {
                                payment_method: {
                                  type: "card",
                                  card: cardElement
                                },
                            }).then(function(result) {
                              // Handle result.error or result.paymentIntent
                              if(result.error)
                              {
                                console.log('Set reason', result.error.message);
                                setError(result.error.message);
                                setLock(false);
                                return;

            
                              }
                              else if(result.paymentIntent)
                              {
      
                                chain = '';
      
                                chain = chain + '&object=' + encodeURIComponent(JSON.stringify(result.paymentIntent));
                                chain = chain + '&invoice=' + encodeURIComponent(response.record); 
                                chain = chain + '&cart=' + encodeURIComponent(JSON.stringify(response.cart)); 
                                
                                onBusy();

                                fetch(configuration.host + "iapi/ajax-finalize-invoice", {
                                  body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
                                  method: "POST",
                                  credentials: 'include',
                                  headers: {
                                        "Content-Type": "application/x-www-form-urlencoded",
                                        "X-Requested-With": 'XMLHttpRequest',
                                        "X_REQUESTED_WITH": 'xmlhttprequest',
                                        'Authorization': 'Bearer ' + jwt
                                    }
                                })
                                .then(res => {
                                    return res.json();
                                })
                                .then(response => {
                                    //console.log(data);
                                    onIdle();

                                    if(response.status === 'OK')
                                    {
                                        onComplete(true);
                                        setLock(false);
                                      }
                                    else
                                    {
                                        setError(response.message);
                                        setLock(false);

                                    }
                                }).catch(function (err) {
                                    onIdle();
                                    setLock(false);
                                    setError("Network error. Please try later: " + err);
                                }); 
      
      
                              }
                            });
                        } 
                        break;




  
                        case 'account_country_invalid_address':
                        case 'account_error_country_change_requires_additional_steps':
                        case 'account_invalid':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'Account configuration error. Pleae contact us';
                        }
                        break;

                        case 'account_number_invalid':
                        {
                          reason = 'The bank account number provided is invalid';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'acss_debit_session_incomplete':
                        {
                            reason = 'The ACSS debit session is not ready to transition to complete status yet. Please try again the request later.';
                            onIdle();
                            setLock(false);

                        }
                        break;
                        

                        case 'alipay_upgrade_required':
                        {
                            reason = 'AliPay configuration error. Please contact us';
                            onIdle();
                            setLock(false);

                        }
                        break;
                                                
                        case 'amount_too_large':
                        {
                            reason = 'The transaction amount is too high. Please contact us';
                            onIdle();
                            setLock(false);

                        }
                        break;

                        case 'amount_too_large':
                        {
                            reason = 'The transaction amount is too low. Please contact us';
                            onIdle();
                            setLock(false);

                        }
                        break;                        

                        case 'api_key_expired':
                        {
                            reason = 'Internal configuration error. Code: APKE. Please contact us';
                            onIdle();
                            setLock(false);

                        }
                        break;   

                        case 'api_key_expired':
                        {
                            reason = 'Internal configuration error. Code: APKE. Please contact us';
                            onIdle();
                            setLock(false);

                        }
                        break; 

                        case 'authentication_required':
                        {
                              onIdle();
                              setLock(false);

                              reason = 'The card was declined as the transaction requires authentication by the card holder.';
                        }
                        break;  
                        
                        case 'balance_insufficient':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'The card was declined as the transaction due to insufficient balance. Please check your card and try again. If the problem persists, please contact your card issuer.';

                        }
                        break;

                        case 'bank_account_declined':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'The charge was declined by the issuing bank. Please contact your bank for further information.';
                        }
                        break;


                        case 'bank_account_exists':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'Duplicate bank account detected.';
                        }
                        break;

                        case 'bank_account_unusable':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'This bank account cannot be used. Please use an alternative account.';
                        }
                        break;                                  


                        case 'bank_account_unverified':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'You have not verified your bank account.';
                        }
                        break;   

                        case 'bank_account_verification_failed':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'Bank account verification has failed.';
                        }
                        break;   
                        case 'billing_invalid_mandate':
                        {
                          onIdle();
                          setLock(false);

                          reason = 'Payment attempted on an invalid mandate.';
                        }
                        break; 

                        case 'bitcoin_upgrade_required':
                        {
                          setLock(false);
                          onIdle();
                          reason = 'Bitcoins cannot be used with this account.';
                        }
                        break;

                        case 'card_declined':
                        {
                            const  decline_code = response?.intent?.object?.decline_code || 0;
                            switch(decline_code)
                            {

                              case 'insufficient_funds':
                              {
                                reason = 'Your card was declined by the issuer due to insufficient funds. Please contact your bank for further information or select another card.';
                                onIdle();
                                setLock(false);

                              }
                              break;
                              case 'lost_card':
                              {
                                reason = 'Your card was declined by the issuer due to it being reported lost. Please contact your bank for further information or select another card.';
                                onIdle();
                                setLock(false);

                              }
                              break;
                              case 'stolen_card':
                              {
                                reason = 'Your card was declined by the issuer due to it being reported lost. Please contact your bank for further information or select another card.';
                                onIdle();
                                setLock(false);

                              }
                              break;

                              default:
                              case 'no_action_taken':
                              case 'merchant_blacklist':
                              case 'generic_decline':
                              {
                                  reason = 'Your card was declined by the issuer. Please contact your bank for further information or select another card.';
                                  onIdle();
                                  setLock(false);

                              }
                              break;

                              // migrate in

                              case 'authentication_required':
                              {
                                  onIdle();
                                  setLock(false);

                                  reason = 'The card was declined as the transaction requires authentication by the card holder.';
                              }
                              break;

                              case 'approve_with_id':
                              {
                                    onIdle();
                                    setLock(false);

                                    reason = 'The payment could not be authorised.';
                              }
                              break; 

                              case 'call_issuer':
                              {
                                    onIdle();
                                    setLock(false);

                                    reason = 'The card has been declined for an unknown reason.';
                              }
                              break; 
                              case 'card_not_supported':
                              {
                                    onIdle();
                                    setLock(false);

                                    reason = 'The card does not support this type of purchase.';
                              }
                              break;                     
                              case 'card_velocity_exceeded':
                              {
                                    reason = 'The customer has exceeded the balance or credit limit available on their card.';
                                    onIdle();
                                    setLock(false);

                              }
                              break;  

                              case 'currency_not_supported':
                              {
                                    reason = 'The card does not support the specified currency.';
                                    onIdle();
                                    setLock(false);

                              }
                              break;   
                              case 'do_not_honor':
                              {
                                    reason = 'The card has been declined by your bank.';
                                    onIdle();
                                    setLock(false);

                              }
                              break;                       
                              case 'do_not_try_again':
                              {
                                    reason = 'The card has been declined for an unknown reason. Future attempts will be declined. Please check the details and try again. ';
                                    onIdle();
                                    setLock(false);

                              }
                              break;                        
                              case 'duplicate_transaction':
                              {
                                onIdle();
                                setLock(false);

                                reason = 'A transaction with identical amount and credit card information was submitted very recently.';
                              }
                              break;                    
                              case 'expired_card':
                              {
                                  reason = 'The card has expired.';
                                  onIdle();
                                  setLock(false);

                              }
                              break; 
                              case 'fraudulent':
                              {
                                  reason = 'The payment has been declined.';
                                  onIdle();
                                  setLock(false);

                              }
                              break; 
      
                              case 'invalid_number':
                              case 'incorrect_number':
                              {
                                  onIdle();
                                  setLock(false);

                                  reason = '	The card number is incorrect.';
                              }
                              break; 

                              case 'invalid_pin':
                              case 'incorrect_pin':
                              {
                                  onIdle();
                                  setLock(false);

                                  reason = 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.';
                              }
                              break; 

                              case 'incorrect_zip':
                              {
                                    reason = 'The ZIP or postal code is incorrect.';
                                    onIdle();
                                    setLock(false);

                              }
                              break; 

                              case 'withdrawal_count_limit_exceeded':
                              {
                                    reason = 'The card has insufficient funds to complete the purchase.';
                                    onIdle();
                                    setLock(false);

                              }
                              break;  

                              case 'new_account_information_available':
                              case 'invalid_account':
                              {
                                  reason = 'The card, or account the card is connected to, is invalid.';
                                  onIdle();
                                  setLock(false);

                              }
                              break;  
                              case 'invalid_amount':
                              {
                                  reason = 'The payment amount is invalid, or exceeds the amount that is allowed.';
                                  onIdle();
                                  setLock(false);

                              }
                              break;  

                              case 'invalid_expiry_month':
                              {
                                reason = 'The expiration month invalid.';
                                onIdle();
                                setLock(false);

            
                              }
                              break;                              

                              case 'invalid_expiry_year':
                              {
                                reason = 'The expiration year invalid.';
                                onIdle();
                                setLock(false);

            
                              }
                              break;

                              case 'invalid_cvc':
                              case 'incorrect_cvc':
                              {
                                  reason = '	The card CVC number is incorrect.';
                                  onIdle();
                                  setLock(false);

                              }
                              break;  
                              
                              case 'issuer_not_available':
                              {
                                reason = 'The card issuer could not be reached, so the payment could not be authorised.';
                                onIdle();
                                setLock(false);

            
                              }
                              break;

                              case 'not_permitted':
                              {
                                  reason = 'The payment is not permitted.';
                                  onIdle();
                                  setLock(false);

              
                              }
                              break;

                              case 'online_or_offline_pin_required':
                              case 'offline_pin_required':
                              {
                                  reason = 'The card has been declined as it requires a PIN.';
                                  onIdle();
                                  setLock(false);

              
                              }
                              break;
                              case 'pickup_card':
                              {
                                  reason = 'The card cannot be used to make this payment.';
                                  onIdle();
              
                              }
                              break;
                              case 'pin_try_exceeded':
                              {
                                  reason = 'The allowable number of PIN tries has been exceeded.';
                                  onIdle();
                                  setLock(false);

        
                              }
                              break;

                                    
                              case 'processing_error':
                              {
                                  reason = 'An error occurred while processing the card.';
                                  onIdle();
                                  setLock(false);

              
                              }
                              break;

                              case 'try_again_later':
                              case 'reenter_transaction':
                              {
                                reason = 'The payment could not be processed by the issuer for an unknown reason.';
                                onIdle();
                                setLock(false);

                              }
                              break;
                              case 'restricted_card':
                              {
                                reason = 'The card cannot be used to make this payment.';
                                onIdle();
                                setLock(false);

                              }
                              break;    
                              
                              case 'revocation_of_authorization':
                              case 'revocation_of_all_authorizations':
                              case 'security_violation':
                              case 'service_not_allowed':
                              case 'stop_payment_order':
                              case 'transaction_not_allowed':
                              {
                                onIdle();
                                setLock(false);

                                reason = 'The card has been declined for an unknown reason.';
                              }
                              break; 
                              
                              case 'testmode_decline':
                              {
                                  reason = 'A Stripe test card number was used.';
                                  onIdle();
                                  setLock(false);

                              }
                              break;

                            }
                        }
                        break;
          
                        case 'card_decline_rate_limit_exceeded':
                        {
                          reason = 'This card has been declined too many times. Please wait 24 hours and try again. Alternatively, please try another';
                          onIdle();
                          setLock(false);

                        }
                        break;
                        case 'cardholder_phone_number_required':
                        {
                          reason = 'We need the card holder phone number to be entered to proceed.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'charge_already_captured':
                        {
                          reason = 'We cannot capture a payment that has already been captured.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'charge_disputed':
                        case 'charge_already_refunded':
                        {
                          reason = 'Unexpected refund error. ' + status;
                          onIdle();
                          setLock(false);

                        }
                        break;    
                        
                        case 'charge_exceeds_source_limit':
                        {
                            reason = 'We have reached our charge limit and cannot proceed. This is temporary. Please try again later and if the issue persists, please contact us.'
                            onIdle();
                            setLock(false);

                        }
                        break;

                        case 'charge_expired_for_capture':
                        {
                            reason = 'Unexpected charge timeout. Please try again.'
                            onIdle();
                            setLock(false);

                        }
                        break;

                        case 'charge_invalid_parameter':
                        {
                            reason = 'Internal charge processing error. Please contact us.'
                            onIdle();
                            setLock(false);

                        }
                        break;                        

                        case 'clearing_code_unsupported':
                        {
                            reason = 'Invalid clearing code provided.'
                            onIdle();
                            setLock(false);

                        }
                        break;     

                        case 'country_code_invalid':
                        case 'country_unsupported':
                        {
                            reason = 'Invalid country information supplied.'
                            onIdle();
                            setLock(false);

                        }
                        break;  
                        
                        case 'expired_card':
                        {
                            reason = 'The card has expired.';
                            onIdle();
                            setLock(false);

                        }
                        break;    
                        
                        case 'email_invalid':
                        {
                          reason = 'The supplied email address is invalid.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'idempotency_key_in_use':
                        {
                          reason = 'System busy. Please try again.';
                          onIdle();
                          setLock(false);

                        }
                        break;                        
                        case 'incorrect_address':
                        {
                          reason = 'Invalid address entered. Please check th details and try again.';
                          onIdle();
                          setLock(false);

                        }
                        break;   
                        case 'incorrect_cvc':
                        {
                              reason = 'The card CVC number is incorrect.';
                              onIdle();
                              setLock(false);

                        }
                        break;    
                        case 'incorrect_number':
                        {
                            setLock(false);
                            onIdle();
                            reason = 'The card number is incorrect.';
                        }
                        break;   

                        case 'incorrect_zip':
                        {
                            reason = 'The ZIP or postal code is incorrect.';
                            onIdle();
                            setLock(false);

                        }
                        break; 

                        case 'insufficient_funds':
                        {
                          reason = 'The card has insufficient funds to complete the purchase.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'intent_invalid_state':
                        case 'intent_verification_method_missing':
                        {
                          reason = 'We are not able to complete this process due to a processing error: ' + status;
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'invalid_card_type':
                        {
                          reason = 'This card type is not supported. Please select an alternative card.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'invalid_characters':
                        {
                          reason = 'One or more fields contain unsupported characters. Please check and try again.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'invalid_charge_amount':
                        {
                          reason = 'Charge failed. An invalid amount was detected.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        case 'invalid_charge_amount':
                        {
                          reason = 'Charge failed. An invalid amount was detected.';
                          onIdle();
                          setLock(false);

                        }
                        break;
                        
                        case 'invalid_cvc':
                        case 'incorrect_cvc':
                        {
                              reason = 'The card CVC number is incorrect.';
                              onIdle();
                              setLock(false);

                        }
                        break; 
                        
                        case 'invalid_expiry_month':
                        {
                          reason = 'The expiration month invalid.';
                          onIdle();
                          setLock(false);

      
                        }
                        break;                              

                        case 'invalid_expiry_year':
                        {
                          reason = 'The expiration year invalid.';
                          onIdle();
                          setLock(false);

      
                        }
                        break;                        

       
                        case 'invalid_number':
                        case 'incorrect_number':
                        {
                            onIdle();
                            setLock(false);

                            reason = '	The card number is incorrect.';
                        }
                        break; 

                        case 'postal_code_invalid':
                        {
                          reason = 'The ZIP or postal code is incorrect.';
                          onIdle();
                          setLock(false);


                        }
                        break;

                        case 'processing_error':
                        {
                              reason = 'An error occurred while processing the card.';
                              onIdle();
                              setLock(false);

          
                        }
                        break; 
                        
                        case 'rate_limit':
                        {
                          reason = 'Temporary rate limit hit. Please wait a few minutes and then try again. If the issue persists, please contact us.';
                          onIdle();
                          setLock(false);

                        }
                        break;

                        default:
                        {
                          reason = 'Unknown error: ' + status;
                          onIdle();
                          setLock(false);

      
                        }
                        break;
                      }
                      console.log('Set reason', reason);
                      //onIdle();
                      setError(reason);
                  }
                  else
                  {
                      onIdle();
                      setLock(false);

                      setError(response.intent.reason);
                  }
                }
            }).catch(function (err) {
                onIdle();
                setLock(false);

                setError("Failed to communicate with the server. If this continues, please check your network and try again : " + error );
                console.log('abort');
            });  
  
  
          }, 
          ( e ) =>{
              console.log("error", e);
              setError("Please refresh your browser. Unable to confirm checkout: " + e);
              onIdle();
  
              setLock(false);

        });  
      }

  }

  
    const isValid = () =>
    {
      if(name.length > 0)
      {
        if(address.length > 0)
        {
          if(town.length > 0)
          {
            if(zip.length > 0)
            {
         
              if(numberValid === true)
              {
                if(cvcValid === true)
                {
                  if(expiryValid === true)
                  {
                    setValid(true);
                    return;                    
                  }
                }
              }
              
            }
          }
        }
      }
      setValid(false);
    }
  
    useEffect(() => {
      isValid();
    });
  
  
    const onUpdateName = ( event ) => {
      setName(event.target.value);
      console.log("update name", event.target.value);
    };
    const onUpdateAddress = ( event ) => {
      setAddress(event.target.value);
      console.log("update address", event.target.value);
    };
    const onUpdateTown = ( event ) => {
      setTown(event.target.value);
      console.log("update town", event.target.value);
    };
    const onUpdateZip = ( event ) => {
      setZip(event.target.value);
      console.log("update zip", event.target.value);
    };
  
  
    const onUpdateCountry = ( event ) => {
      setCountry(event.target.value);
      console.log("update country", event.target.value);
    }; 
   
    return (
        <div className="container-fluid no-gutters">    

            <form onSubmit={handleSubmit} autocomplete="on">               
                <div className="row">
                    <div className="col-sm-12" >
                        <div className="form-group">
                            <label htmlFor="name">Cardholder name</label>
                            <input  type="text" 
                                    className="form-control "
                                    autoComplete="cc-name"
                                    name="ccname"
                                    id="name"
                                    required 
                                    pattern="[0-9A-Za-z-., ]+"      
                                    placeholder="Name as written on the credit or debit card" 
                                    onChange={onUpdateName} /> 
                                    
                        </div>
                    </div>                                                                                                                     
                </div>
                <div className="row">
                    <div className="col-sm-12" >
                        <div className="form-group">
                            <label htmlFor="address">Billing address</label>
                            <input  type="text" 
                                    className="form-control"
                                    autoComplete="street-address"
                                    id="address"
                                    name="address"
                                    required 
                                    pattern="[0-9A-Za-z-., ]+"      
                                    placeholder="Registered house number and name of street"
                                    onChange={onUpdateAddress} /> 
                        </div>
                    </div>                                                                                                                     
                </div> 
                <div className="row">
                    <div className="col-sm-12 col-md-6" >
                        <div className="form-group">
                            <label htmlFor="town">Town</label>
                            <input  type="text" 
                                    className="form-control "
                                    id="town"
                                    name="city"
                                    autoComplete='address-level1'
                                    required 
                                    pattern="[0-9A-Za-z-., ]+"      
                                    placeholder="Registered Town or city"  
                                    onChange={onUpdateTown} /> 
                        </div>
                    </div>                                                                                                                     
                </div> 
                <div className="row">
                    <div className="col-sm-12 col-md-6" >
                        <div className="form-group">
                            <label htmlFor="zip">Postal or zip code</label>
                            <input  type="text" 
                                    className="form-control "
                                    id="zip"
                                    autoComplete='postal-code'
                                    name="zip"
                                    required 
                                    pattern="[0-9A-Za-z-., ]+"      
                                    placeholder="Registered post code"  
                                    onChange={onUpdateZip} /> 
                        </div>
                    </div>                                                                                                                     
                </div> 
                <div className="row">
                    <div className="col-sm-12 col-md-6" >
                        <div className="form-group">
                            <label htmlFor="country">Country</label>
                            <select className="form-control" id="countrySelect" name="country" autoComplete='country' value={country} onChange={onUpdateCountry}>
                            <option value="GB">United Kingdom</option>
                              <option value="US">United States</option>
                              <option value="NZ">New Zealand</option>
                              <option value="AU">Australia</option>
                              <option value="AF">Afghanistan</option>
                              <option value="AX">Åland Islands</option>
                              <option value="AL">Albania</option>
                              <option value="DZ">Algeria</option>
                              <option value="AS">American Samoa</option>
                              <option value="AD">Andorra</option>
                              <option value="AO">Angola</option>
                              <option value="AI">Anguilla</option>
                              <option value="AQ">Antarctica</option>
                              <option value="AG">Antigua and Barbuda</option>
                              <option value="AR">Argentina</option>
                              <option value="AM">Armenia</option>
                              <option value="AW">Aruba</option>
                              <option value="AT">Austria</option>
                              <option value="AZ">Azerbaijan</option>
                              <option value="BS">Bahamas</option>
                              <option value="BH">Bahrain</option>
                              <option value="BD">Bangladesh</option>
                              <option value="BB">Barbados</option>
                              <option value="BY">Belarus</option>
                              <option value="BE">Belgium</option>
                              <option value="BZ">Belize</option>
                              <option value="BJ">Benin</option>
                              <option value="BM">Bermuda</option>
                              <option value="BT">Bhutan</option>
                              <option value="BO">Bolivia</option>
                              <option value="BA">Bosnia and Herzegovina</option>
                              <option value="BW">Botswana</option>
                              <option value="BV">Bouvet Island</option>
                              <option value="BR">Brazil</option>
                              <option value="IO">British Indian Ocean Territory</option>
                              <option value="BN">Brunei Darussalam</option>
                              <option value="BG">Bulgaria</option>
                              <option value="BF">Burkina Faso</option>
                              <option value="BI">Burundi</option>
                              <option value="KH">Cambodia</option>
                              <option value="CM">Cameroon</option>
                              <option value="CA">Canada</option>
                              <option value="CV">Cape Verde</option>
                              <option value="KY">Cayman Islands</option>
                              <option value="CF">Central African Republic</option>
                              <option value="TD">Chad</option>
                              <option value="CL">Chile</option>
                              <option value="CN">China</option>
                              <option value="CX">Christmas Island</option>
                              <option value="CC">Cocos (Keeling) Islands</option>
                              <option value="CO">Colombia</option>
                              <option value="KM">Comoros</option>
                              <option value="CG">Congo</option>
                              <option value="CD">Congo, The Democratic Republic of The</option>
                              <option value="CK">Cook Islands</option>
                              <option value="CR">Costa Rica</option>
                              <option value="CI">Cote D'ivoire</option>
                              <option value="HR">Croatia</option>
                              <option value="CU">Cuba</option>
                              <option value="CY">Cyprus</option>
                              <option value="CZ">Czechia</option>
                              <option value="DK">Denmark</option>
                              <option value="DJ">Djibouti</option>
                              <option value="DM">Dominica</option>
                              <option value="DO">Dominican Republic</option>
                              <option value="EC">Ecuador</option>
                              <option value="EG">Egypt</option>
                              <option value="SV">El Salvador</option>
                              <option value="GQ">Equatorial Guinea</option>
                              <option value="ER">Eritrea</option>
                              <option value="EE">Estonia</option>
                              <option value="ET">Ethiopia</option>
                              <option value="FK">Falkland Islands (Malvinas)</option>
                              <option value="FO">Faroe Islands</option>
                              <option value="FJ">Fiji</option>
                              <option value="FI">Finland</option>
                              <option value="FR">France</option>
                              <option value="GF">French Guiana</option>
                              <option value="PF">French Polynesia</option>
                              <option value="TF">French Southern Territories</option>
                              <option value="GA">Gabon</option>
                              <option value="GM">Gambia</option>
                              <option value="GE">Georgia</option>
                              <option value="DE">Germany</option>
                              <option value="GH">Ghana</option>
                              <option value="GI">Gibraltar</option>
                              <option value="GR">Greece</option>
                              <option value="GL">Greenland</option>
                              <option value="GD">Grenada</option>
                              <option value="GP">Guadeloupe</option>
                              <option value="GU">Guam</option>
                              <option value="GT">Guatemala</option>
                              <option value="GG">Guernsey</option>
                              <option value="GN">Guinea</option>
                              <option value="GW">Guinea-bissau</option>
                              <option value="GY">Guyana</option>
                              <option value="HT">Haiti</option>
                              <option value="HM">Heard Island and Mcdonald Islands</option>
                              <option value="VA">Holy See (Vatican City State)</option>
                              <option value="HN">Honduras</option>
                              <option value="HK">Hong Kong</option>
                              <option value="HU">Hungary</option>
                              <option value="IS">Iceland</option>
                              <option value="IN">India</option>
                              <option value="ID">Indonesia</option>
                              <option value="IR">Iran, Islamic Republic of</option>
                              <option value="IQ">Iraq</option>
                              <option value="IE">Ireland</option>
                              <option value="IM">Isle of Man</option>
                              <option value="IL">Israel</option>
                              <option value="IT">Italy</option>
                              <option value="JM">Jamaica</option>
                              <option value="JP">Japan</option>
                              <option value="JE">Jersey</option>
                              <option value="JO">Jordan</option>
                              <option value="KZ">Kazakhstan</option>
                              <option value="KE">Kenya</option>
                              <option value="KI">Kiribati</option>
                              <option value="KP">Korea, Democratic People's Republic of</option>
                              <option value="KR">Korea, Republic of</option>
                              <option value="KW">Kuwait</option>
                              <option value="KG">Kyrgyzstan</option>
                              <option value="LA">Lao People's Democratic Republic</option>
                              <option value="LV">Latvia</option>
                              <option value="LB">Lebanon</option>
                              <option value="LS">Lesotho</option>
                              <option value="LR">Liberia</option>
                              <option value="LY">Libyan Arab Jamahiriya</option>
                              <option value="LI">Liechtenstein</option>
                              <option value="LT">Lithuania</option>
                              <option value="LU">Luxembourg</option>
                              <option value="MO">Macao</option>
                              <option value="MK">Macedonia, The Former Yugoslav Republic of</option>
                              <option value="MG">Madagascar</option>
                              <option value="MW">Malawi</option>
                              <option value="MY">Malaysia</option>
                              <option value="MV">Maldives</option>
                              <option value="ML">Mali</option>
                              <option value="MT">Malta</option>
                              <option value="MH">Marshall Islands</option>
                              <option value="MQ">Martinique</option>
                              <option value="MR">Mauritania</option>
                              <option value="MU">Mauritius</option>
                              <option value="YT">Mayotte</option>
                              <option value="MX">Mexico</option>
                              <option value="FM">Micronesia, Federated States of</option>
                              <option value="MD">Moldova, Republic of</option>
                              <option value="MC">Monaco</option>
                              <option value="MN">Mongolia</option>
                              <option value="ME">Montenegro</option>
                              <option value="MS">Montserrat</option>
                              <option value="MA">Morocco</option>
                              <option value="MZ">Mozambique</option>
                              <option value="MM">Myanmar</option>
                              <option value="NA">Namibia</option>
                              <option value="NR">Nauru</option>
                              <option value="NP">Nepal</option>
                              <option value="NL">Netherlands</option>
                              <option value="AN">Netherlands Antilles</option>
                              <option value="NC">New Caledonia</option>
                              <option value="NZ">New Zealand</option>
                              <option value="NI">Nicaragua</option>
                              <option value="NE">Niger</option>
                              <option value="NG">Nigeria</option>
                              <option value="NU">Niue</option>
                              <option value="NF">Norfolk Island</option>
                              <option value="MP">Northern Mariana Islands</option>
                              <option value="NO">Norway</option>
                              <option value="OM">Oman</option>
                              <option value="PK">Pakistan</option>
                              <option value="PW">Palau</option>
                              <option value="PS">Palestinian Territory, Occupied</option>
                              <option value="PA">Panama</option>
                              <option value="PG">Papua New Guinea</option>
                              <option value="PY">Paraguay</option>
                              <option value="PE">Peru</option>
                              <option value="PH">Philippines</option>
                              <option value="PN">Pitcairn</option>
                              <option value="PL">Poland</option>
                              <option value="PT">Portugal</option>
                              <option value="PR">Puerto Rico</option>
                              <option value="QA">Qatar</option>
                              <option value="RE">Reunion</option>
                              <option value="RO">Romania</option>
                              <option value="RU">Russian Federation</option>
                              <option value="RW">Rwanda</option>
                              <option value="SH">Saint Helena</option>
                              <option value="KN">Saint Kitts and Nevis</option>
                              <option value="LC">Saint Lucia</option>
                              <option value="PM">Saint Pierre and Miquelon</option>
                              <option value="VC">Saint Vincent and The Grenadines</option>
                              <option value="WS">Samoa</option>
                              <option value="SM">San Marino</option>
                              <option value="ST">Sao Tome and Principe</option>
                              <option value="SA">Saudi Arabia</option>
                              <option value="SN">Senegal</option>
                              <option value="RS">Serbia</option>
                              <option value="SC">Seychelles</option>
                              <option value="SL">Sierra Leone</option>
                              <option value="SG">Singapore</option>
                              <option value="SK">Slovakia</option>
                              <option value="SI">Slovenia</option>
                              <option value="SB">Solomon Islands</option>
                              <option value="SO">Somalia</option>
                              <option value="ZA">South Africa</option>
                              <option value="GS">South Georgia and The South Sandwich Islands</option>
                              <option value="ES">Spain</option>
                              <option value="LK">Sri Lanka</option>
                              <option value="SD">Sudan</option>
                              <option value="SR">Suriname</option>
                              <option value="SJ">Svalbard and Jan Mayen</option>
                              <option value="SZ">Swaziland</option>
                              <option value="SE">Sweden</option>
                              <option value="CH">Switzerland</option>
                              <option value="SY">Syrian Arab Republic</option>
                              <option value="TW">Taiwan, Province of China</option>
                              <option value="TJ">Tajikistan</option>
                              <option value="TZ">Tanzania, United Republic of</option>
                              <option value="TH">Thailand</option>
                              <option value="TL">Timor-leste</option>
                              <option value="TG">Togo</option>
                              <option value="TK">Tokelau</option>
                              <option value="TO">Tonga</option>
                              <option value="TT">Trinidad and Tobago</option>
                              <option value="TN">Tunisia</option>
                              <option value="TR">Turkey</option>
                              <option value="TM">Turkmenistan</option>
                              <option value="TC">Turks and Caicos Islands</option>
                              <option value="TV">Tuvalu</option>
                              <option value="UG">Uganda</option>
                              <option value="UA">Ukraine</option>
                              <option value="AE">United Arab Emirates</option>
                              <option value="GB">United Kingdom</option>
                              <option value="US">United States</option>
                              <option value="UM">United States Minor Outlying Islands</option>
                              <option value="UY">Uruguay</option>
                              <option value="UZ">Uzbekistan</option>
                              <option value="VU">Vanuatu</option>
                              <option value="VE">Venezuela</option>
                              <option value="VN">Viet Nam</option>
                              <option value="VG">Virgin Islands, British</option>
                              <option value="VI">Virgin Islands, U.S.</option>
                              <option value="WF">Wallis and Futuna</option>
                              <option value="EH">Western Sahara</option>
                              <option value="YE">Yemen</option>
                              <option value="ZM">Zambia</option>
                              <option value="ZW">Zimbabwe</option>
                            </select>

                        </div>
                    </div>                                                                                                                     
                </div> 

                <div className="row">
                    <div className="col-sm-12">
                        <h5 className={styles.p__detail}>Card details</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6" >
                        <div className="form-group">
                            <label htmlFor="card">Card number</label>
                            <CardNumberElement
                                options={options}
                                className={styles.p__card}
                                name="cardnumber"
                                autoComplete="cc-number"
                                onReady={() => {
                                console.log("CardNumberElement [ready]");
                                }}
                                onChange={event => {
                                console.log("CardNumberElement [change]", event);
                                setNumberValid(event.complete);
                                }}
                                onBlur={() => {
                                console.log("CardNumberElement [blur]");
                                }}
                                onFocus={() => {
                                console.log("CardNumberElement [focus]");
                                }}
                            />
                        </div>                  
                    </div>
                    <div className="col-sm-12 col-md-3" >
                        <div className="form-group">
                            <label htmlFor="expiry">Expiry</label>
                            <CardExpiryElement
                                options={options}
                                className={styles.p__card}
                                autoComplete="cc-exp"
                                name="exp-date"
                                onReady={() => {
                                console.log("CardExpiryElement [ready]");
                                }}
                                onChange={event => {
                                console.log("CardExpiryElement [change]", event);
                                setExpiryValid(event.complete);
    
                                }}
                                onBlur={() => {
                                console.log("CardExpiryElement [blur]");
                                }}
                                onFocus={() => {
                                console.log("CardExpiryElement [focus]");
                                }}
                            />
                        </div>                  
                    </div>

                    <div className="col-sm-12 col-md-3" >
                        <div className="form-group">
                            <label htmlFor="expiry">CVV</label>
                            <CardCvcElement
                            options={options}
                            name="cvc"
                            autoComplete="cc-csc"
                            className={styles.p__card}
                            onReady={() => {
                              console.log("CardNumberElement [ready]");
                            }}
                            onChange={event => {
                              console.log("CardNumberElement [change]", event);
                              setCVCValid(event.complete);
                            }}
                            onBlur={() => {
                              console.log("CardNumberElement [blur]");
                            }}
                            onFocus={() => {
                              console.log("CardNumberElement [focus]");
                            }}
                          />
                        </div>                  
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 pl-0" >
                      <div className="form-group">
                        <div class="form-check">
                              <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" value={save} required={false} onChange={(e) => {setSave(e.target.checked)}} /> 
                                Save these card details for future use. 
                              </label> 
                            </div> 
                      </div>
                </div>
                {
                  terms.length > 0 && <div>
                        <fieldset class="form-group">
                        <h5 className={styles.p__detail}>Terms and conditions</h5>
                        {
                          terms.map( term => (
                            <div class="form-check" key={"ctid-"+term.tid}>
                              <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" value={term.tid} required={true} /> 
                                {term.text}
                              </label> < a href={term.link} target="_blank">Click here to read.</a>
                            </div>  
                          ))  
                        }
                        {
                          customer_terms.map( term => (
                            <div class="form-check" key={"cust-tid-"+term.tid}>
                              <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" value={term.tid} required={true} /> 
                              </label><div className="customerTerms">{parse(decode(term.text))}</div>
                            </div>  
                          ))  
                        }
                        </fieldset>                   
                  </div>

                }
                
                
                <div>
                    <button onClick={() => function(product, option){}} disabled={disable || lock} className={"btn btn-sm btn-block " + styles.p__rVieBtn}>Pay {formatNumber(price, currency)}</button>
                </div>


            </form>   

            {
                error !== '' && (
                    <ChargeError onDone={onErrorClear} show={true} error={error} />
                )
            }  
        </div>
    );
  };
  



  const CardForm = (props) => {
    const {price, currency, terms, customer_terms, disable, cart, voucher, onIdle, onBusy, onComplete,  country} = props;
    const stripe_object = loadStripe(window.key, {stripeAccount: window.acct});

    return (
        <Elements stripe={stripe_object}>
              <ElementsConsumer>
              {({elements, stripe}) => (
                 <StripeCardForm elements={elements} default_country={country} stripe={stripe} price={price} currency={currency} terms={terms} customer_terms={customer_terms} disable={disable}  cart={cart} voucher={voucher} onIdle={onIdle} onBusy={onBusy} onComplete={onComplete} />

              )}

              </ElementsConsumer>

        </Elements>

    );
  };
  export default CardForm;
  