
import React, { Component } from 'react';
import { useEffect, useState } from "react";
import styles from "./Discount.module.scss"
import { useMemo } from "react";


const DiscountForm = ({onChange, value}) => {

    const [voucher, setVoucher] = React.useState(value);

    const onVoucherChange = ( e ) => {
        setVoucher(e.target.value);
        console.log("update voucher", e.target.value);
  
    }

    const onSubmitForm = ( e ) => {
        e.preventDefault();
        onChange( voucher );
    }

    return (
        <div className="container-fluid no-gutters rvNSDiscount">    
            <form onSubmit={onSubmitForm}>              
                <div className="row">
                    <div className="col-sm-12 mb-2" >
                        <div className="form-group">
                            <label htmlFor="name">Enter voucher code</label>
                            <div className="input-group">
                            <input  type="text" 
                                    className="form-control "
                                    id="voucher"
                                    autoFocus 
                                    required 
                                    pattern="[0-9A-Za-z-_% ]+"      
                                    placeholder="Enter voucher"
                                    value={voucher}
                                    onChange={onVoucherChange}
                                    />
                                   <div className="input-group-append ml-3 d-none d-md-block d-lg-block d-xl-block">
                                         <button type="submit" className="btn btn-sm btn-outline-secondary vButton pl-4 pr-4" onClick={()=>{}}>Apply voucher</button>
                                   </div>
                            </div>

                        </div>
                    </div>                                                                                                                     
                </div>
                <div className="d-block d-md-none d-lg-none d-xl-none">
                    <button type="submit" className="btn btn-sm btn-outline-secondary" onClick={()=>{}}>Apply voucher</button>
                </div>
            </form>    
        </div>
    );
  };
  

  export default DiscountForm;
  