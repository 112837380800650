
import React, { Component } from 'react';
import { useEffect, useState } from "react";
import styles from "./ChoosePaymentType.module.scss"
import { useMemo } from "react";


const ChoosePaymentTypeForm = ({cards, card, onChange}) => {

    const onCardChange = (event) => {
        onChange(event.target.value);
    }

    console.log('setting card to ', card);
    return (
        <div className="container-fluid no-gutters">    
            <form>              
                <div className="row mb-4 mt-4">
                    <div className="col-sm-12" >
                        <div className="form-group">
                            <label htmlFor="card">Choose a payment option</label>
                            <select className="form-control" id="cardSelect" value={card.id} onChange={onCardChange}>
                              {
                                cards.map( card => (
                                    <option key={'card-' + card.id} value={card.id}>{card.name}</option>
                                ))   
                              }
                            </select>

      
                        </div>
                    </div>                                                                                                                     
                </div>
            </form>    
        </div>
    );
  };
  

  export default ChoosePaymentTypeForm;
  