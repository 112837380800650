import React from 'react';

const QuestionaireRadio = ({id, text, index, required, options, answer, onAnswer }) => {

    return ( 
        <React.Fragment>
            <div>
                <p className="mt-4">{index}. {text}</p>
                      {
                          options.map( (o, i) => (
                            <div class="form-check" key={id + '-' + o.id}>
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" id={id} name={id} required={required} value={o.id} autoFocus={index ===1? true: false } checked={o.id === answer.answer} onChange={(e) => {onAnswer(id, e.target.value)}} />
                                    {o.text}
                                </label>
                            </div>              
                          ))
                      }
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireRadio;