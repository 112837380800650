
import React from 'react';
import styles from "./ExistingPaymentType.module.scss"
import ChargeError from './ChargeError';
import {configuration} from '../../services/Checkout.settings.service';
import {CartService} from '../../services/cart.service';
import { JwtContext } from '../../contexts/JwtContext';
import parse from 'html-react-parser';


const FreePaymentTypeForm = ({ currency, terms, customer_terms, cart, voucher, onComplete, onIdle, onBusy, disable}) => {
    const [error, setError] = React.useState('');
    const {jwt} = React.useContext(JwtContext);
    const [lock, setLock] = React.useState(false);

    const onErrorClear = () => {
        setError('');
    }

    const onBookNow = (e) =>{
        e.preventDefault();
        if(lock)
        {
            console.error("Duplicate transaction");
        }

        const signature = cart.signature;
        onBusy();

        setLock(true)

        CartService.lock(cart, voucher, jwt,
            (rc)=>{
              console.log("returned cart", rc);

              if(signature !== rc.signature)
              {
                // something has changed - as the customer to double check
                setError("We have detected a change with your account. This may affect the price that you pay. Please re-check the cart. If you are happy to proceed, please try again");
                onIdle();
                setLock(false);
                return;
              }


              let chain = '';
              chain = chain + '&fee=' + 0;
              chain = chain + '&currency=' + encodeURIComponent(currency);
              chain = chain + '&voucher=' + encodeURIComponent(voucher); 
              chain = chain + '&invoice=' + ''; 
              chain = chain + '&cart=' + encodeURIComponent(JSON.stringify(cart));

              fetch(configuration.host + "iapi/ajax-finalize-free-booking", {
                  body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
                  method: "POST",
                  credentials: 'include',
                  headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "X-Requested-With": 'XMLHttpRequest',
                      "X_REQUESTED_WITH": 'xmlhttprequest',
                      'Authorization': 'Bearer ' + jwt                      

                  }
              })
              .then(res => {
                  return res.json();
              })
              .then(response => {
                  //console.log(data);
                  if(response.status === 'OK')
                  {
                      onIdle();
                      onComplete(true);
                  }
                  else
                  {
                      onIdle();
                      setError(response.message);
                      setLock(false);

                  }
              }).catch(function (err) {
                    onIdle();
                    setError("Network error. Please try later: " + err);
                    setLock(false);
                });  

            },
            (e) => {
                console.log("error", e);
                setError("Please refresh your browser. Unable to confirm checkout: " + e);
                onIdle();
                setLock(false);
            });       
    }



    const decode = (a) => {
      
        var s = window.atob(a);
        return s;
      }

    return (
        <div className="container-fluid no-gutters">    
            <form onSubmit={onBookNow}>                
                <div className="row">
                    <div className="col-12">
                    {
                        terms.length > 0 && 
                        <div>
                                <fieldset class="form-group">
                                <h5 className={styles.p__detail}>Terms and conditions</h5>
                                {
                                terms.map( term => (
                                    <div key={"etid-"+term.tid} class="form-check">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" value={term.tid} required={true} />
                                        {term.text}
                                    </label> < a href={term.link} target="_blank">Click here to read.</a>
                                    </div>  
                                ))  
                                }
                                {
                                customer_terms.map( term => (
                                    <div class="form-check" key={"cust-tid-"+term.tid}>
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" value={term.tid} required={true} /> 
                                    </label><div className="customerTerms">{parse(decode(term.text))}</div>
                                    </div>  
                                ))  
                                }
                                </fieldset>                   
                        </div>

                    }                        
                    </div>
                </div>             
                <div>
                    <button type="submit"  disabled={disable} className={"btn btn-sm btn-block " + styles.p__rVieBtn}>Book now</button>
                </div>
            </form>  


            {
                error !== '' && (
                    <ChargeError onDone={onErrorClear} show={true} error={error} />
                )
            } 

        </div>


    );
  };
  

  export default FreePaymentTypeForm;
  