

export const settings = {
    'currency': window.currency? window.currency : '', 
    'isloggedIn': window.isLoggedIn? true : false,
    'terms': window.terms? window.terms : [],
    'customer_terms': window.customer_terms? window.customer_terms : [],
    'mailactive': window.mailactive? window.mailactive : false,
    'mailsubscribed': window.mailsubscribed ? true : false,
    'questionaire': (typeof window.questionaire != "undefined") ? window.questionaire : false,
    'answers': (typeof window.answers != "undefined") ? window.answers : {answers:[]},
    };


export const configuration = {
    host: window.host? window.host:'', 
    embedded: window !== window.top,
    tokenname: window.tokenname,
    tokenvalue: window.tokenvalue
};

