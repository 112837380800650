import React from 'react';

const QuestionaireMultiTextBox = ({id, text, index, required, answer, onAnswer }) => {
    const jsonUnescape = (str) =>{
        return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
    }
    return ( 
        <React.Fragment>
            <div>
                <div className="form-group">
                    <label className="col-form-label mt-4" for={id}>{index}. {text}</label>
                    <textarea className="form-control" rows="3" id={id} name={id} required={required} autoFocus={id===1? true: false } onChange={(e) => {onAnswer(id, e.target.value)}} autoComplete={id + Date.now().toString()}>{jsonUnescape(answer.answer)}</textarea>
                </div>                
            </div>
        </React.Fragment>
        

     );
}
export default QuestionaireMultiTextBox;