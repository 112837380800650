import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import Routes from './routes';
import CheckoutControllerContextProvider from './contexts/CheckoutControllerContext';
import JwtContextProvider from './contexts/JwtContext';

ReactDOM.render(
  <React.StrictMode>
        <JwtContextProvider>
        <HelmetProvider>
            <CheckoutControllerContextProvider>
                <Routes />
            </CheckoutControllerContextProvider>
        </HelmetProvider>
        </JwtContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
