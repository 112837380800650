import React, { useState } from 'react';
import {configuration} from '../../services/Checkout.settings.service'
import styles from './Wait.module.scss';
import ReCAPTCHA from "react-google-recaptcha";

const WAIT_STATE_INITIAL = 'WAIT_STATE_INITIAL';
const WAIT_STATE_DONE = 'WAIT_STATE_DONE';

const WaitBlock = ({onChildIdle, onChildBusy, onRefreshSize}) => {

    const [ stage, setStage ] = useState( WAIT_STATE_INITIAL ); 
    const [ tok, setTok ] = useState( null ); 
    const [ firstname, setFirstname ] = useState( '' ); 
    const [ lastname, setLastname ] = useState( '' ); 
    const [ email, setEmail ] = useState( '' ); 
    const [ error, setError ] = useState( '' ); 

    onChildBusy()

    const handleClose = () => {
 //       clearWait();
    }

    const onTokenChange = (value) => {
        console.log("Captcha value:", value);
        setTok( value ); 
    }

    const onSetFirstName = ( event ) => {
        setFirstname(event.target.value);
    }


    const onSetLastName = ( event ) => {
        setLastname(event.target.value);
    }
    const onSetEmail = ( event ) => {
        setEmail(event.target.value);
    }

    React.useEffect(() => {
        onRefreshSize();
        onChildIdle();
    })

    const onReacaptchaLoaded = () => {
        console.log('recaptcha loaded');
        onRefreshSize();        
    }



    const onContinue = ( event ) => {
        event.preventDefault();
        //var x = { firstname: firstname, lastname: lastname, email: email, token: tok};
        const urlParams = new URLSearchParams(window.location.search);
        console.log('search params', window.location.search);
        const eid = urlParams.get('eid')
        const did = urlParams.get('did')
        var chain = '';
        chain += 'firstname= ' + encodeURIComponent(firstname);
        chain += '&lastname= ' + encodeURIComponent(lastname);
        chain += '&email= ' + encodeURIComponent(email);
        chain += '&eid= ' + eid;
        chain += '&did= ' + did;
        chain += '&token= ' + tok;

        if(eid <= 0)
        {
            setError('Unable select event waiting list');
            return;
        }

        if(did <= 0)
        {
            setError('Unable select date waiting list'); 
            return;         
        }
        onChildBusy()

        fetch(configuration.host + "api/ajax-json-add-to-waiting-list", {
            method: "POST",
            body: chain,
            mode: "cors",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                setStage(WAIT_STATE_DONE);
                onChildIdle();
            }
            else
            {
                onChildIdle();
                setError(response.message);
            }
        }).catch(function (err) {
            onChildIdle();
            setError('Network error: ' + err);
        });    

    }


    switch(stage)
    {
        case WAIT_STATE_INITIAL:
        default:
            return (
                <React.Fragment>
                    <form onSubmit={onContinue}>
                        <div className={"row"}>
                            <div className="col-12">
                                <p>Thank you, unfortunately this event is currently fully booked. If you would like join this event's waiting list, please complete the following form:</p>
                            </div>
                        </div>
    
                        <div className={"row"}>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="waitInputFName1">First name</label>
                                    <input type="text" className="form-control" id="waitInputFName1" aria-describedby="fnameHelp" placeholder="Enter your first name" value={firstname} autoFocus required onChange={onSetFirstName} pattern="[a-zA-Z- ]{1,200}" />
                                    <small id="fnameHelp" className="form-text text-muted">Your first name. Allowed characters: A-Z, a-z and '-'</small>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="waitInputLName1">Last name</label>
                                    <input type="text" className="form-control" id="waitInputLName1" aria-describedby="lnameHelp" placeholder="Enter your last name" value={lastname} required  onChange={onSetLastName} pattern="[a-zA-Z- ]{1,200}"/>
                                    <small id="lnameHelp" className="form-text text-muted">Your last name. Allowed characters: A-Z, a-z and '-'</small>
                                </div>
                            </div>                    
                        </div>
    
                        <div className={"row"}>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="waitInputEmail1">Email address</label>
                                    <input type="email" className="form-control" id="waitInputEmail1" required aria-describedby="emailHelp" placeholder="name@someaddress.com" onChange={onSetEmail} />
                                    <small id="emailHelp" className="form-text text-muted">Please enter your email address. We will use this to notify you of availability.</small>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="waitInputRecaptcha">Check humanity</label>
                                    <ReCAPTCHA
                                        id="waitInputRecaptcha"
                                        sitekey="6Lcszg4TAAAAAMrhOiwDFgNj4FMMBu_Nf_OeKOJs"
                                        onChange={onTokenChange}
                                        asyncScriptOnLoad={onReacaptchaLoaded}
                                    />
                                </div>
                            </div>
                        </div>
    
                        {
                            error !== '' && (
                                <div>
                                    <div className="alert alert-danger" role="alert">
                                        {error}
                                    </div>
                                </div>
                            )
                        }
                        <div className={"row"}>
                            <div className="col-12">
                                <button onClick={() => function(product, option){}} type="submit" className={"btn btn-sm btn-block " + styles.p__rVieBtn}>Join waiting list</button>
                            </div>
                        </div>
                    </form>
                </React.Fragment>        
    
            );


            case WAIT_STATE_DONE:
                return (
                    <React.Fragment>
                        <p>Thank you. You have been added to this event's waiting list. If a space becomes available we will be in touch. </p>
                    </React.Fragment>
                );

    }


  }
  

  export default WaitBlock;