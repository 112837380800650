import React from 'react';
import styles from './Mailing.module.scss';
import {settings} from '../../services/Checkout.settings.service'

const Mailing = ({state, onChange}) => {     

    return ( 
        <React.Fragment>
            {
                settings.mailactive && 
                    <div className="row mb-4">
                        <div className="col-12">
                            <h5 className={styles.p__SectionTitle}>Mail preferences</h5>
                            <p className={"mb-3 mt-3 " + styles.p__Info}>Would you like to join our newsletter? If you join our newsletter, we will send regular updates.</p>
                            <fieldset className="form-group">
                                <div className="form-check">
                                    <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" value="1" checked={state} onChange={(e) => {e.preventDefault(); onChange(!state)}}/> Yes, I would like to sign up for the newsletters.
                                    </label>
                                </div>
                            </fieldset>          
                        </div>
                    </div>
            }
        </React.Fragment>
     );
}
 
export default Mailing;