import React from 'react';
import Moment from 'react-moment';


export const PassExpires = ( props ) => {
    const { product, styles } = props;
    const used                =  product.transactions?.reduce( (previous, current) => {
        const sub =  current?.objects?.reduce((v, o) => {
            const x = o?.key_3?.credits || 0;
            if(o?.deleted)
            {
                return v;
            }
            else
            {
                return v + (x * o.qty);
            }
        }, 0);
        return previous + sub;
    }, 0);

    const start_mode          = parseInt(product.start_mode);
    const period              = parseInt(product.period);
    const credits             = (parseInt(product.credits) - used).toFixed(0);
    const expiry_range        = parseInt(product.expiry_range);


    return (
        <React.Fragment>
        {
            // new payment
            product.mode === 'credit' &&
            start_mode !== 1 &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {credits}</p>
        }
        {
            // new payment
            product.mode === 'credit' &&
            start_mode === 1 && period === 0 &&
            product.expires === null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: On date/time of first booking. Pass Expires:  {expiry_range} day(s) after date of first booking. Credits remaining after transaction after booking:  {credits}</p>
        }
        {
            // new payment
            product.mode === 'credit' &&
            start_mode === 1 && period === 1 &&
            product.expires === null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: On date/time of first booking. Pass Expires:  {expiry_range} month(s) after date of first booking. Credits remaining after transaction:  {credits}</p>
        }   
        {
            // new payment
            product.mode === 'credit' &&
            start_mode === 1 && period === 0 &&
            product.expires !== null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {credits}</p>
        }
        {
            // new payment
            product.mode === 'credit' &&
            start_mode === 1 && period === 1 &&
            product.expires !== null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {credits}</p>
        }  





        {
            // exisiting payment
            product.mode === 'debit' &&
            start_mode !== 1 &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {credits}</p>
        }
        {
            // existing payment - expiry set
            product.mode === 'debit' &&
            start_mode === 1 && 
            period === 0 &&
            product.expires !== null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {credits}</p>
        }
        {
            // existing payment - expiry set
            product.mode === 'debit' &&
            start_mode === 1 && 
            period === 1 &&
            product.expires !== null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {credits}</p>
        }  
        {
            // existing payment - expiry not set
            product.mode === 'debit' &&
            start_mode === 1 && 
            period === 0 &&
            product.expires === null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires:  {expiry_range} day(s) after date of first booking. Credits remaining after transaction after booking:  {credits}</p>
        }
        {
            // existing payment - expiry not set
            product.mode === 'debit' &&
            start_mode === 1 && 
            period === 1 &&
            product.expires === null &&
            <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires:  {expiry_range} month(s) after date of first booking. Credits remaining after transaction after booking:  {credits}</p>
        }  
                

        </React.Fragment>
    );
  }
  
