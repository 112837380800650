import React, { useContext } from 'react';
import styles from './Login.module.scss';
import {configuration} from '../../services/Checkout.settings.service'
import { CookieService } from '../../services/cookie.service';
import { JwtContext } from '../../contexts/JwtContext';

const LOGIN_STATE_LOGIN = 'LOGIN_STATE_LOGIN';
const LOGIN_STATE_REGISTER_START = 'LOGIN_STATE_REGISTER_START';
const LOGIN_STATE_REGISTER_CODE = 'LOGIN_STATE_REGISTER_CODE';
const LOGIN_STATE_REGISTER_CUSTOMER = 'LOGIN_STATE_REGISTER_CUSTOMER';
const LOGIN_STATE_REGISTER_COMPLETE = 'LOGIN_STATE_REGISTER_COMPLETE';


const LOGIN_STATE_RESET_START = 'LOGIN_STATE_RESET_START';
const LOGIN_STATE_RESET_CODE = 'LOGIN_STATE_RESET_CODE';
const LOGIN_STATE_RESET_SET_PASSWORD = 'LOGIN_STATE_RESET_SET_PASSWORD';


const LogInCombined = ({onChildIdle, onChildBusy, onReady, onRefreshSize}) => {
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [passwordType, setPasswordType] = React.useState('password');
    const [passwordText, setPasswordText] = React.useState('Show password');
    const [state, setState] = React.useState(LOGIN_STATE_LOGIN);
    //const [state, setState] = React.useState(LOGIN_STATE_RESET_SET_PASSWORD);
    const [allowed, setNextAllowed] = React.useState(true);

    const [code, setCode] = React.useState('');
    const [remember, setRemember] = React.useState(false);
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [error, setError] = React.useState('')
    const ref = React.useRef()
    const {jwt} = React.useContext(JwtContext);


    const onShowPassword = (event) => {
        event.preventDefault();
        if(passwordType === 'password')
        {
            setPasswordType('text');
            setPasswordText('Hide password');
        }
        else
        {
            setPasswordType('password');
            setPasswordText('Show password');
        }
    }

    const onSetUsername = ( event ) =>{
        setUsername(event.target.value);
    }

    const onSetPassword = ( event ) =>{
        setPassword(event.target.value);
    }



    const onDoLogin = (event) => {
        event.preventDefault();
        onChildBusy();
        setError('');
        var chain = '&email=' + encodeURIComponent(username);
        chain = chain + '&password=' + password;
        chain = chain + '&remember=' + remember;
        fetch(configuration.host + "iapi/ajax-do-customer-log-in", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt,
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            console.log(response);
            if(response.status === 'OK')
            {

                if(remember)
                {
                    const x = {
                        email: username,
                        password: password,
                        token: true,
                        ttoken: false,
                        at: response.autoken
                    }

                    CookieService.setCookie('rvli', response.token, 365);
                    console.log(x);
                    onReady(x);   
                }
                else
                {
                    const x = {
                        email: username,
                        password: password,
                        ttoken: true,
                        token: false,
                        at: response.autoken
                    }
                    CookieService.setCookie('trvli', response.token, 1);
                    console.log(x);
                    onReady(x);                      
                }

                onChildIdle();
            }
            else
            {
                setError('Log in failed. Please check your username and password and try again.')
                onChildIdle();
            }
        }).catch(function (err) {
            setError('Network error. Please try again.')
            onChildIdle();
            onReady();                
        }); 
    } 
    
    

    const onRegisterStart = ( e ) =>{
        e.preventDefault();
        console.log('register start');
        setState(LOGIN_STATE_REGISTER_START)
    }


    const onRegisterEmailSubmit = ( e ) =>{
        e.preventDefault();
        onChildBusy();
        setNextAllowed(false);
        console.log('config', configuration);

        var chain = '&email=' + encodeURIComponent(username);
        setError('');

        fetch(configuration.host + "iapi/ajax-do-customer-register-flow-v2-request-code", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt,
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                console.log('register start');
                setState(LOGIN_STATE_REGISTER_CODE);
                setNextAllowed(true);

            }
            else
            {
                setError(response.message);
                onChildIdle();
                setNextAllowed(true);
            }
        }).catch(function (err) {
            onChildIdle();
            setNextAllowed(true);
            setError('Network error. Please check your connection and try again.');
        }); 



    }


    const onResetEmailSubmit = ( e ) =>{
        e.preventDefault();
        onChildBusy();
        console.log('config', configuration);

        var chain = '&email=' + encodeURIComponent(username);
        setError('');
        setNextAllowed(false);

        fetch(configuration.host + "iapi/ajax-do-customer-reset-flow-v2-request-code", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                console.log('register start');
                setState(LOGIN_STATE_RESET_CODE);
                setNextAllowed(true);

            }
            else
            {
                setError(response.message);
                onChildIdle();
                setNextAllowed(true);

            }
        }).catch(function (err) {
            onChildIdle();
            setNextAllowed(true);
            setError('Network error. Please check your connection and try again.');
        });         
    }

    const onResetCheckcode = ( e ) =>{
        e.preventDefault();
        onChildBusy();
        console.log('config', configuration);
        setError('');
        setNextAllowed(false);

        var chain = '&code=' + code;

        fetch(configuration.host + "iapi/ajax-do-customer-register-flow-v2-check-code", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                console.log('register start');
                setState(LOGIN_STATE_RESET_SET_PASSWORD);
                setNextAllowed(true);
            }
            else
            {
                setError(response.message);
                onChildIdle();
                setNextAllowed(true);
            }
        }).catch(function (err) {
            onChildIdle();
            setError('Network error. Please check your connection and try again.');
            setNextAllowed(true);

        });         
    }

    const onResetChangePassword = ( e ) =>{
        e.preventDefault();
        onChildBusy();
        console.log('config', configuration);
        setError('');
        setNextAllowed(false);

        var chain = '&password=' + password;

        fetch(configuration.host + "iapi/ajax-do-customer-reset-flow-v2-change-password", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                const x = {
                    email: username,
                    password: password,
                    token: false,
                    ttoken: true,
                    at: response.autoken
                }
                onReady(x);
                setNextAllowed(true);
                console.log('register start');
                setState(LOGIN_STATE_REGISTER_COMPLETE)
            }
            else
            {
                setError(response.message);
                setNextAllowed(true);
                onChildIdle();
            }
        }).catch(function (err) {
            onChildIdle();
            setNextAllowed(true);
            setError('Network error. Please check your connection and try again.');
        });         
    }


    const onSetCode = (e) => {
        e.preventDefault();
        setCode(e.target.value);
    }


    const onCheckCode = (e) => {
        e.preventDefault();

        var chain = '&code=' + code;
        setError('');
        setNextAllowed(false);

        fetch(configuration.host + "iapi/ajax-do-customer-register-flow-v2-check-code", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                console.log('register start');
                setState(LOGIN_STATE_REGISTER_CUSTOMER);
                setNextAllowed(true);
            }
            else
            {
                setError(response.message);
                setNextAllowed(true);
                onChildIdle();
            }
        }).catch(function (err) {
            onChildIdle();
            setNextAllowed(true);
            setError('Network error. Please check your connection and try again.');
        }); 

       
    }


    const onCreateAccount = (e) => {
        e.preventDefault();

        var chain = 'password=' + password;
        chain = chain + '&firstname=' + firstname;
        chain = chain + '&lastname=' + lastname;
        chain = chain + '&mobile=' + mobile;

        setError('');

        setNextAllowed(false);

        fetch(configuration.host + "iapi/ajax-do-customer-register-flow-v2-finalize", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                console.log('register start');


                const x = {
                        email: username,
                        password: password,
                        token: false,
                        ttoken: true,
                        at: response.autoken

                }
                console.log(x);
                onReady(x);
                setNextAllowed(true);
                setState(LOGIN_STATE_REGISTER_COMPLETE)
            }
            else
            {
                setError(response.message);
                onChildIdle();
                setNextAllowed(true);

            }
        }).catch(function (err) {
            onChildIdle();
            setNextAllowed(true);
            setError('Network error. Please check your connection and try again.');
        }); 

    }

    React.useEffect(() =>{
        onRefreshSize();
        setNextAllowed(true);
     }, [state, error])


     switch(state)
     {
         default:
         case LOGIN_STATE_LOGIN:
            return ( 
                <React.Fragment>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-7 border-md-right border-lg-right border-xl-right">
                                <div className={styles.p__signin}>
                                    <h2 className={styles.p__title}>Sign in</h2>
                                    <p className="d-none d-sm-block d-md-block">If you have an account with us, please sign in.</p>
                                    <form onSubmit={onDoLogin}>
                                        <div className={"form-group " + styles.p__form}>
                                            <label htmlFor="checkoutInputEmail">Email address</label>
                                            <input type="email" className={"form-control"} id="checkoutInputEmail" placeholder="Enter your email" value={username} autoFocus={true} required={true} onChange={onSetUsername}/>
                                        </div>
                                        <div className={"form-group " + styles.p__form}>
                                            <label htmlFor="checkoutInputPassword">Password</label>
                                            <div className={"text-right " + styles.p__forgot}>
                                                <a href="#" className={styles.p__forgotLnk} onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_RESET_START)}}>Forgotten password?</a>                                    
                                            </div>
                                            <input type={passwordType} className={"form-control"} id="checkoutInputPasssword" password={password} placeholder="Enter your password" onChange={onSetPassword} required/>
                                        </div>
        
                                        <div className={styles.p__show}>
                                            <a href="#" onClick={onShowPassword}>{passwordText}</a>                                    
                                        </div>
                                        <div className={"form-check " + styles.p__form}>
                                            <input class="form-check-input" type="checkbox" checked={!!remember} name='remember' id='remember'  value={remember}  onClick={(e) => {setRemember(!remember)}}></input>
                                            <label class="form-check-label" for="remember">
                                                Remember me
                                            </label>
                                        </div>   
                                        {
                                                error &&
                                                <div class="alert alert-danger mt-2">
                                                    <strong>Oops!</strong> {error}
                                                </div>
                                        }                                                                             
                                        <div className="mb-2">
                                            <button onClick={() => function(product, option){}} className={"btn btn-sm btn-block " + styles.p__rVieBtn}>Sign in</button>
                                        </div>
                                        <div className="text-center d-md-none d-lg-block d-xl-block d-xl-none">
                                                <a href="#" className={styles.p__forgotLnk} onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_REGISTER_START)}}>No account? Register now</a>                                    
                                        </div>
                                    </form> 
        
                                </div> 
                            </div>
                            <div className="col-sm-5 col-md-5 d-none d-md-block">
                                <h2 className={styles.p__title}>New customer?</h2>
                                <p>No account? No problem. Creating an account is quick and easy. Click 'create account' below to get started.</p>
                                <div className="mt-0">
                                    <button
                                            onClick={onRegisterStart}
                                            className={"btn btn-sm btn-outline-secondary btn-block " + styles.p__rVieCreateBtn}>create acount</button>
        
                                </div>
                            </div>              
                        </div>
                </React.Fragment>
             );
            case LOGIN_STATE_REGISTER_START:
                return ( 
                    <React.Fragment>
                            <div className={"row"}>
                                <div className="col-sm-12 col-md-8 pb-4">
                                    <div className={styles.p__signin}>
                                        <h2 className={styles.p__title}>Create an account</h2>
                                        <p>To get started, please tell us your email address and then press continue to proceed </p>
                                        <form onSubmit={onRegisterEmailSubmit}>
                                                <div className={"form-group " + styles.p__form}>
                                                    <label htmlFor="checkoutInputEmail">Email address</label>
                                                    <input type="email" className={"form-control"} id="checkoutInputEmail" placeholder="Enter your email" value={username} autoFocus={true} required={true} onChange={onSetUsername}/>
                                                </div>

                                                {
                                                    error &&
                                                    <div class="alert alert-danger">
                                                        <strong>Oops!</strong> {error}
                                                    </div>
                                                }
                                                <div className="row no-gutters">
                                                    <div className={"col-12  btn-box"}>
                                                    <button
                                                        type="submit"
                                                        onClick={() => function(product, option){}}
                                                        className={"btn btn-sm btn-outline-primary fright " + styles.p__rVieCreateBtn} disabled={!allowed}>Next &gt;&gt; </button>

                                                        <button
                                                        type="button"
                                                        onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_LOGIN)}}
                                                        className={"btn btn-sm btn-outline-secondary fleft " + styles.p__rVieCreateBtn} disabled={!allowed}> &lt;&lt;&nbsp;Back </button>
                                                    </div>
                                                    <div className={"col-md-6 col-6 text-right"}>
                                                    </div>
                                                </div>
        
                                        </form> 
            
                                    </div> 
                                </div>         
                            </div>
                    </React.Fragment>
                ); 
             
             case LOGIN_STATE_REGISTER_CODE:
                return ( 
                    <React.Fragment>
                            <div className={"row"}>
                                <div className="col-sm-12 col-md-8 pb-4">
                                    <div className={styles.p__signin}>
                                        <h2 className={styles.p__title}>Thank you, almost there</h2>
                                        <p className="pt-2">To verify the email address you have given us, we have sent you a code. Please copy and paste this in to the box provided and click continue to proceed</p>
                                        <form onSubmit={onCheckCode}>
                                            <div className={"form-group " + styles.p__form}>
                                                <label htmlFor="checkoutInputCode">Verification code</label>
                                                <input type="text" className={"form-control"} id="checkoutInputCode" placeholder="Enter the code here as provided" value={code} autoFocus={true} required={true} onChange={onSetCode}/>
                                            </div>
                                            {
                                                error &&
                                                <div class="alert alert-danger">
                                                    <strong>Oops!</strong> {error}
                                                </div>
                                            }
                                            <div className="row no-gutters">
                                                <div className={"col-12  btn-box "}>
                                                    <button
                                                    type="submit"
                                                    onClick={() => function(product, option){}}
                                                    className={"btn btn-sm btn-outline-primary fright " + styles.p__rVieCreateBtn} disabled={!allowed}>Next &gt;&gt; </button>

                                                    <button
                                                    type="button"
                                                    onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_REGISTER_START)}}
                                                    className={"btn btn-sm btn-outline-secondary fleft " + styles.p__rVieCreateBtn} disabled={!allowed}> &lt;&lt;&nbsp;Back </button>
                                                                                                    </div>
                                            </div>                                                                                
                                        </form> 
            
                                    </div> 
                                </div>         
                            </div>
                    </React.Fragment>
                 );              

             case LOGIN_STATE_REGISTER_CUSTOMER:
                return ( 
                    <React.Fragment>
                            <div className={"row"}>
                                <div className="col-sm-12 pb-4">
                                    <div className={styles.p__signin}>
                                        <h2 className={styles.p__title}>Final step!</h2>
                                        <p className="pt-2">We just need a few more details. Please complete the form and press the 'create account' button to finish.</p>
                                        <form onSubmit={onCreateAccount}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className={"form-group " + styles.p__form}>
                                                        <label htmlFor="checkoutInputFirstname">First name</label>
                                                        <input type="text" className={"form-control"} id="checkoutInputFirstname" placeholder="First name" value={firstname} autoFocus={true} required={true} onChange={(e) => setFirstname(e.target.value)}/>
                                                    </div>

                                                </div>
                                                <div className="col-6">
                                                    <div className={"form-group " + styles.p__form}>
                                                        <label htmlFor="checkoutInputLastname">Last name</label>
                                                        <input type="text" className={"form-control"} id="checkoutInputLastname" placeholder="Last name" value={lastname} required={true} onChange={(e)=>{setLastname(e.target.value)}}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                    <div className="col-12">
                                                        <div className={"form-group " + styles.p__form}>
                                                            <label htmlFor="checkoutInputPassword">Password</label>
                                                            <input type={passwordType} className={"form-control"} id="checkoutInputPasssword" password={password} placeholder="Enter your password" onChange={onSetPassword}  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, a symbol such as -,!,~,_ and at least 8 or more characters" required/>
                                                            <small id="checkoutInputPasssword" class="form-text text-muted">Passwords must contain at least 8 characters and use uppercase, lowercase characters and one or more numerical digits.</small>
                                                        </div>
                                                    </div>
                                            </div>                              
                                            <div className="row">
                                                    <div className={"col-12 " + styles.p__show} >
                                                        <a href="#" onClick={onShowPassword}>{passwordText}</a>                                    
                                                    </div>
                                            </div> 

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className={"form-group " + styles.p__form}>
                                                        <label htmlFor="checkoutInputEmail">Mobile number</label>
                                                        <input type="tel" className={"form-control"} id="checkoutInputEmail" placeholder="mobile number" value={mobile}  required={true} onChange={(e)=> setMobile(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>  
                                            {
                                            error &&
                                            <div class="alert alert-danger">
                                                <strong>Oops!</strong> {error}
                                            </div>
                                            }
                                            <div className="row no-gutters">
                                                <div className={"col-12  btn-box "}>
                                                    <button
                                                    type="submit"
                                                    onClick={() => function(product, option){}}
                                                    className={"btn btn-sm fright " + styles.p__rViePurpleBtn} disabled={!allowed}>Complete</button>

                                                    <button
                                                    type="button"
                                                    onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_REGISTER_START)}}
                                                    className={"btn btn-sm btn-outline-secondary fleft " + styles.p__rVieCreateBtn} disabled={!allowed}> &lt;&lt;&nbsp;Back </button>
                                                </div>
                                            </div>

                                        </form> 
            
                                    </div> 
                                </div>         
                            </div>
                    </React.Fragment>
                 );  

                 case LOGIN_STATE_REGISTER_COMPLETE:
                    return ( 
                        <React.Fragment>
                                <div className={"row"}>
                                    <div className="col-sm-12 pb-4">
                                        <div className={styles.p__signin}>
                                            <h2 className={styles.p__title}>Success!</h2>
                                            <p className="pt-2">Thank you - your account is setup is now complete. Please standby whilst we log you in to your account.</p>
                                        </div> 
                                    </div>         
                                </div>
                        </React.Fragment>
                     );  


                     case LOGIN_STATE_RESET_START:
                        return ( 
                            <React.Fragment>
                                    <div className={"row"}>
                                        <div className="col-sm-12 col-md-8 pb-4">
                                            <div className={styles.p__signin}>
                                                <h2 className={styles.p__title}>Reset password</h2>
                                                <p>To get started, please tell us your email address and then press continue to proceed </p>
                                                <form onSubmit={onResetEmailSubmit}>
                                                        <div className={"form-group " + styles.p__form}>
                                                            <label htmlFor="checkoutInputEmail">Email address</label>
                                                            <input type="email" className={"form-control"} id="checkoutInputEmail" placeholder="Enter your email" value={username} autoFocus={true} required={true} onChange={onSetUsername}/>
                                                        </div>
        
                                                        {
                                                            error &&
                                                            <div class="alert alert-danger">
                                                                <strong>Oops!</strong> {error}
                                                            </div>
                                                        }
                                                        <div className="row no-gutters">
                                                            <div className={"col-12  btn-box"}>
                                                                <button
                                                                type="submit"
                                                                onClick={() => function(product, option){}}
                                                                className={"btn btn-sm btn-outline-primary fright " + styles.p__rVieCreateBtn} disabled={!allowed}>Next &gt;&gt; </button>

                                                                <button
                                                                type="button"
                                                                onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_LOGIN)}}
                                                                className={"btn btn-sm btn-outline-secondary fleft " + styles.p__rVieCreateBtn} disabled={!allowed}> &lt;&lt;&nbsp;Back </button>
                                                            
                                                            </div>
                                                        </div>
                
                                                </form> 
                    
                                            </div> 
                                        </div>         
                                    </div>
                            </React.Fragment>
                        );     

                        case LOGIN_STATE_RESET_CODE:
                            return ( 
                                <React.Fragment>
                                    <div className={"row"}>
                                        <div className="col-sm-12 col-md-8 pb-4">
                                            <div className={styles.p__signin}>
                                                <h2 className={styles.p__title}>Thank you, almost there</h2>
                                                <p className="pt-2">We have sent you a code. Please copy and paste this in to the box provided and click continue to proceed</p>
                                                <form onSubmit={onResetCheckcode}>
                                                    <div className={"form-group " + styles.p__form}>
                                                        <label htmlFor="checkoutInputCode">Verification code</label>
                                                        <input type="text" className={"form-control"} id="checkoutInputCode" placeholder="Enter the code here as provided" value={code} autoFocus={true} required={true} onChange={onSetCode}/>
                                                    </div>
                                                    {
                                                        error &&
                                                        <div class="alert alert-danger">
                                                            <strong>Oops!</strong> {error}
                                                        </div>
                                                    }
                                                    <div className="row no-gutters">
                                                        <div className={"col-12  btn-box "}>
                                                            <button
                                                            type="submit"
                                                            onClick={() => {}}
                                                            className={"btn btn-sm btn-outline-primary fright " + styles.p__rVieCreateBtn} disabled={!allowed} >Next &gt;&gt; </button>

                                                            <button
                                                            type="button"
                                                            onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_RESET_START)}}
                                                            className={"btn btn-sm btn-outline-secondary fleft " + styles.p__rVieCreateBtn} disabled={!allowed}> &lt;&lt;&nbsp;Back </button>
                                                        </div>
                                                        
                                                    </div>                                                                                
                                                </form> 
                    
                                            </div> 
                                        </div>         
                                    </div>
                                </React.Fragment>                                

                             );                      
                         
                             
                             case LOGIN_STATE_RESET_SET_PASSWORD:
                                return ( 
                                    <React.Fragment>
                                        <div className={"row"}>
                                            <div className="col-sm-12 col-md-8 pb-4">
                                                <div className={styles.p__signin}>
                                                    <h2 className={styles.p__title}>Thank you, almost there</h2>
                                                    <p className="pt-2">Please enter a new password below</p>
                                                    <form onSubmit={onResetChangePassword}>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className={"form-group " + styles.p__form}>
                                                                    <label htmlFor="checkoutInputPassword">Password</label>
                                                                    <input type={passwordType} className={"form-control"} id="checkoutInputPasssword" password={password} placeholder="Enter your password" onChange={onSetPassword}  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, a symbol such as -,!,~,_ and at least 8 or more characters" required/>
                                                                </div>
                                                            </div>
                                                        </div>                              
                                                        <div className="row">
                                                            <div className={"col-12 " + styles.p__show} >
                                                                <a href="#" onClick={onShowPassword}>{passwordText}</a>                                    
                                                            </div>
                                                        </div> 
                                                        {
                                                            error &&
                                                            <div class="alert alert-danger">
                                                                <strong>Oops!</strong> {error}
                                                            </div>
                                                        }
                                                        <div className="row no-gutters">
                                                            <div className={"col-12 btn-box"}>

                                                            <button
                                                                onClick={() => function(product, option){}}
                                                                className={"btn btn-sm btn-outline-primary fright " + styles.p__rVieCreateBtn} disabled={!allowed}>Next &gt;&gt; </button>


                                                                <button
                                                                onClick={(e) => {e.preventDefault(); setState(LOGIN_STATE_RESET_CODE)}}
                                                                className={"btn btn-sm btn-outline-secondary fleft " + styles.p__rVieCreateBtn} disabled={!allowed}> &lt;&lt;&nbsp;Back </button>
                                                                
                                                            </div>
                                                        </div>                                                                                
                                                    </form> 
                        
                                                </div> 
                                            </div>         
                                        </div>
                                    </React.Fragment>                                
    
                                 ); 
                             
                        

     }


}

 
export default LogInCombined;