import React, { createContext, useReducer } from 'react';
import { JwtReducer } from './JwtReducer';

export const JwtContext = createContext();



const initialState = { 
                        jwt: ''
                     };



const JwtContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(JwtReducer, initialState);

    const storeJwt = payload => {
        dispatch({type: 'UPDATE_JWT', payload})
    }


    const contextValues = {
        storeJwt,
        ...state
    } 

    return ( 
        <JwtContext.Provider value={contextValues} >
            { children }
        </JwtContext.Provider>
     );
}
 
export default JwtContextProvider;
