import React from 'react';
import styles from './Error.module.scss';

const ChargeError = ({ show, onDone, error}) => {

    React.useEffect(() => {
        // ask the parent to re-rendeer
        const url = window !== window.top ? document.referrer : document.location.href;

        const y = {message: 'rvRepostition', data:[], position: 'top', height: document.getElementById('root').clientHeight };
        window.parent.postMessage(JSON.stringify(y), url);    
    }, [])

    const handleClose = () => {
        onDone()
    }



    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Error</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form >
                        {
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 mb-2 mt-2">
                                        <p className="mb-2">Payment processing error: {error}</p>
                                    </div>
                                </div>

                            </div>

                        }
                    </form>  
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onDone}>Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default ChargeError;