import React from 'react';

const QuestionaireCheckbox = ({id, text, index, required, answer, onAnswer }) => {


    return ( 
        <React.Fragment>
            <div>
                {
                    <div class="form-check mt-4"  key={id}>
                        <input class="form-check-input" type="checkbox" value={id} checked={!!answer.answer} name={id} id={id} required={required} value={id}  autoFocus={id===1? true: false } onClick={(e) => {onAnswer(id, !answer.answer)}}></input>
                        <label class="form-check-label" for={id}>
                        {text}
                        </label>
                    </div>              
                }
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireCheckbox;