import React from 'react';
import styles from './Attendees.module.scss';

function initialize( attendees, list ) {
    let x = [];
    for(let i = 0; i < attendees; i++)
    {
        if(typeof list[i] === 'object')
        {
            x.push(list[i]);
        }
        else
        {
            x.push({ id: i, type: (i === 0)?'me':'other', firstname: '', lastname: '', email: '', mobile: '' });
        }

    }
    return x;
}


const Attendees = ({attendees, attendeelist, filters, onChildIdle, onChildBusy, onRefreshSize, onReady}) => {

    const [mode, setMode] = React.useState(1);
    const [records, setRecords] = React.useState(initialize(attendees > 0 ? attendees: 1, attendeelist));

    const onNext = (e) => {
        e.preventDefault();
        onReady(records);
    }

    const onPerson1Change = ( e ) => {
        let x = JSON.parse(JSON.stringify(records));
        x[0].type = e.target.value;
        setRecords(x);
    }

    const onFirstNameChange = (z, e) => {
        let x = JSON.parse(JSON.stringify(records));
        x[z].firstname = e.target.value;
        setRecords(x);
    }
    const onLastNameChange = (z, e) => {
        let x = JSON.parse(JSON.stringify(records));
        x[z].lastname = e.target.value;
        setRecords(x);
    }

    const onEmailChange = (z, e) => {
        let x = JSON.parse(JSON.stringify(records));
        x[z].email = e.target.value;
        setRecords(x);
    }

    const onTelChange = (z, e) => {
        let x = JSON.parse(JSON.stringify(records));
        x[z].mobile = e.target.value;
        setRecords(x);
    }

    React.useEffect(() => {
        onRefreshSize();
    },[records[0].type])


    return ( 
        <React.Fragment>
            <form onSubmit={onNext}>
                <div className="row">
                    <div className={"col-12 p-4 "}>
                        <h4 className={"mb-4 "}>Attendees</h4>
                    </div>
                    <div className={"col-12 p-4 "}>
                        <p className={styles.p__info}>You have told us that you are booking for {attendees==1? '1 person':attendees + ' people'}. Please tell us who you are booking in:</p>
                    </div>
                </div>
                {
                    records.map( (attendee, i) => (
                    <div className="row" key={'attendee-' + i}>
                        <div className="col-sm-12">
                            <div class="card bg-secondary mb-3">
                                <div class="card-header">Person {(i+1)}</div>
                                <div class="card-body">
                                        <div className="container-fluid">
                                            {
                                                i === 0 &&
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <h6 class="card-title">who is person {i+1}?</h6>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="col-sm-12 mt-2">
                                                                <div className="form-check">
                                                                    <label className="form-check-label">
                                                                        <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="me" onChange={onPerson1Change} defaultChecked={true} checked={records[0].type === "me"? true : false}/> person 1 is me
                                                                    </label>  
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 mt-2">
                                                                <div className="form-check">
                                                                    <label className="form-check-label">
                                                                        <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios2" value="other"  onChange={onPerson1Change} disabled={!!!filters.policy_event_book_others} checked={records[0].type === "other"? true : false} />
                                                                        person 1 is someone else 
                                                                    </label>                                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {
                                                ((i === 0 && records[0].type === 'other') || i > 0) &&
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <h6 class="card-title">Please provide details of person {i+1} below:</h6>
                                                        </div> 
                                                    </div>
                                                   
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div class="form-group">
                                                                <label for={"p" + (i+1) + "FirstName"} class="form-label mt-4">First name</label>
                                                                <input type="text" required class="form-control" id={"p" + (i+1) + "FirstName"} autofocus={i==0} aria-describedby={"p1" + (i+1) + "FirstNameHelp"} value={attendee.firstname}  onChange={(e) => {onFirstNameChange(i, e)}} placeholder="Enter first name" pattern="[A-Za-z0-9-]{1, 256}" />
                                                                <small id={"p1" + (i+1) + "FirstNameHelp"} class="form-text text-muted">Enter the person's first name</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div class="form-group">
                                                                <label for={"p" + (i+1) + "LastName"} class="form-label mt-4">Last name</label>
                                                                <input type="text" required class="form-control" id={"p" + (i+1) + "LastName"} aria-describedby={"p1" + (i+1) + "LastNameHelp"} value={attendee.lastname} placeholder="Enter last name" pattern="[A-Za-z0-9-]{1, 256}"   onChange={(e) => {onLastNameChange(i, e)}} />
                                                                <small id={"p1" + (i+1) + "LastNameHelp"} class="form-text text-muted">Enter the person's first name</small>
                                                            </div>
                                                        </div>                                            
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div class="form-group">
                                                                <label for={"p" + (i+1) + "Email"} class="form-label mt-4">Email address</label>
                                                                <input type="email" required class="form-control" id={"p" + (i+1) + "Email"} value={attendee.email}  aria-describedby="emailHelp" placeholder="Enter email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" onChange={(e) => {onEmailChange(i, e)}} />
                                                                <small id="emailHelp" class="form-text text-muted">Enter the person's email address.</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div class="form-group">
                                                                <label for={"p" + (i+1) + "Tel"} class="form-label mt-4">Mobile number</label>
                                                                <input type="tel" required class="form-control" id={"p" + (i+1) + "Tel"} value={attendee.mobile}  aria-describedby="telHelp" placeholder="Enter mobile" pattern="[+0-9- ]{10,25}" onChange={(e) => {onTelChange(i, e)}}/>
                                                                <small id="telHelp" class="form-text text-muted">Enter the person's mobile number.</small>
                                                            </div>
                                                        </div>                                            
                                                    </div>  
                                                </React.Fragment>                                      
                                            }
                                        </div>                                                 

                                    <p class="card-text"></p>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    ))
                }

                  
    
                <div className="row">
                    <div className="col-12">
                        <button onClick={() => {}} type="submit" className={"btn btn-sm btn-block " + styles.p__rVieBtn}>Next</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
     );
}
 
export default Attendees;