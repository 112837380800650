import React from 'react';
import parse from 'html-react-parser';

const QuestionaireTextBlock = ({id, text, index, required, answer, onAnswer }) => {
    const jsonUnescape = (str) =>{
        const x =   str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n\\r/g, "<br/>").replace(/\\r\\n/g, "<br/>").replace(/\\r/g, "<br/>").replace(/\\n/g, "<br/>").replace(/\n/g, "<br/>").replace(/\r/g, "<br/>");
        return x;
    }
    return ( 
        <React.Fragment>
            <div>
                <div className="form-group mt-4">
                    {parse(jsonUnescape(text))}
                </div>                
            </div>
        </React.Fragment>
        

     );
}
export default QuestionaireTextBlock;