import React, { useContext } from 'react';
import styles from './Login.module.scss';
import {configuration} from '../../services/Checkout.settings.service'
import { JwtContext } from '../../contexts/JwtContext';
import { CookieService } from '../../services/cookie.service';

const LogIn = ({onChildIdle, onChildBusy, onReady}) => {
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [passwordType, setPasswordType] = React.useState('password');
    const [passwordText, setPasswordText] = React.useState('Show password');
    const {storeJwt, jwt} = React.useContext(JwtContext);

    const onShowPassword = (event) => {
        event.preventDefault();
        if(passwordType === 'password')
        {
            setPasswordType('text');
            setPasswordText('Hide password');
        }
        else
        {
            setPasswordType('password');
            setPasswordText('Show password');
        }
    }

    const onSetUsername = ( event ) =>{
        setUsername(event.target.value);
    }

    const onSetPassword = ( event ) =>{
        setPassword(event.target.value);
    }



    const onDoLogin = (event) => {
        event.preventDefault();
        onChildBusy();
        console.log('config', configuration);

        var chain = '&email=' + encodeURIComponent(username);
        chain = chain + '&password=' + password;
        fetch(configuration.host + "iapi/ajax-do-customer-log-in", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt,
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                const x = {
                    email: username,
                    password: password,
                    ttoken: true,
                    token: false,
                    at: response.autoken
                }
                CookieService.setCookie('trvli', response.token, 1);
                storeJwt(response.autoken);
                console.log(x);
                onReady(x);  
                onChildIdle();
            }
            else
            {
                onChildIdle();
            }
        }).catch(function (err) {
            onChildIdle();
        }); 
    }  




    return ( 
        <div className="container-fluid">
            <div className="col-12">
                <div className={styles.p__signin}>
                    <h2 className={styles.p__title}>Sign in</h2>
                    <form onSubmit={onDoLogin}>
                        <div className={"form-group " + styles.p__form}>
                            <label htmlFor="checkoutInputEmail">Email address</label>
                            <input type="email" className={"form-control"} id="checkoutInputEmail" placeholder="Enter your email" value={username} autoFocus={true} required={true} onChange={onSetUsername}/>
                        </div>
                        <div className={"form-group " + styles.p__form}>
                            <label htmlFor="checkoutInputPassword">Password</label>
                            <input type={passwordType} className={"form-control"} id="checkoutInputPasssword" password={password} placeholder="Enter your password" onChange={onSetPassword} required/>
                        </div>
                        <div className={styles.p__show}>
                            <a href="#" onClick={onShowPassword}>{passwordText}</a>                                    
                        </div>
                        <div>
                            <button 
                                onClick={() => function(product, option){}}
                                className={"btn btn-sm btn-block " + styles.p__rVieBtn}>Sign in</button>
                        </div>
                        <div className={"row " + styles.p__manageLinks}>
                            <div  className={"col-sm-6 " + styles.p__forgotLink}>
                                <a href={window.rv_reservie_address + "account/reset/"}  className={styles.p__forgotLink}>Forgotten password? Click here to reset.</a>
                            </div>
                        </div>

                    </form>  
                </div> 
            </div>
          
        </div>
        

     );
}
 
export default LogIn;