import React, { createContext, useReducer } from 'react';
import {CheckoutControllerReducer} from './CheckoutControllerReducer';

export const CheckoutControllerContext = createContext()

export const CHECKOUT_STAGE_INITIALISE = -1;
export const CHECKOUT_STAGE_ACCOUNT = 0;
export const CHECKOUT_STAGE_INFO = 1;
export const CHECKOUT_STAGE_ANALYZE_PAYMENT_TYPE = 2;
export const CHECKOUT_LOCK_EVENTS = 4;
export const CHECKOUT_STAGE_ATTENDEES = 5;
export const CHECKOUT_STAGE_PAYCARD = 6;
export const CHECKOUT_STAGE_PAYPASS = 7;
export const CHECKOUT_STAGE_PURCHASE_COMPLETE = 8;
export const CHECKOUT_STAGE_CART_EMPTY = 9;
export const CHECKOUT_STAGE_FREE = 10;

const initialState = { 
   stage: CHECKOUT_STAGE_INITIALISE
//    stage: CHECKOUT_STAGE_INFO
 //   stage: CHECKOUT_STAGE_ANALYZE_PAYMENT_TYPE
  };


const CheckoutControllerContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(CheckoutControllerReducer, initialState);


    const setStage = payload => {
        dispatch({type: 'SET_STAGE', payload})
    }

    

    const contextValues = {
        setStage,
        ...state
    } 

    return ( 
        <CheckoutControllerContext.Provider value={contextValues} >
            <React.Fragment>
            { children }
            </React.Fragment>
        </CheckoutControllerContext.Provider>
     );
}
 
export default CheckoutControllerContextProvider;