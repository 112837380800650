import React from 'react';

const QuestionaireTextBox = ({id, text, index, required, answer, onAnswer }) => {

    return ( 
        <React.Fragment>
            <div>
                <div className="form-group">
                    <label className="col-form-label mt-4" for="inputDefault">{index}. {text}</label>
                    <input type="text" className="form-control" placeholder="" id={id} name={id} value={answer.answer} required={required} autoFocus={id===1? true: false } autoComplete={id + Date.now().toString()} onChange={(e) => {
                        console.log("Updated text", e.target.value);
                        onAnswer(id, e.target.value);
                    }} />
                </div>                
            </div>
        </React.Fragment>
     );
}
export default QuestionaireTextBox;