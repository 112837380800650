import React from 'react';

const QuestionaireMultiCheckbox = ({id, text, index, required, options, answer, onAnswer }) => {

    const is_array = Array.isArray(answer);
    console.log("parameters", required);
    const jsonUnescape = (str) =>{
        return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
    }

    return ( 
        <React.Fragment>
            <div>
                <p className="mt-4">{index}. {text}</p>
                      {
                          options.map( (o, i) => (
                            <div class="form-check" key={id + '-' + o.id}>
                                <label className="form-check-label">
                                    {
                                        is_array && 
                                        <input type="checkbox" className="form-check-input" id={id} name={id} required={required === true} value={o.id} autoFocus={index ===1? true: false } checked={!!answer.find((ans) => (ans.answer === o.id))} onChange={(e) => {onAnswer(id, e.target.value)}} />
                                    }
                                    {
                                        (!!!is_array) && 
                                        <input type="checkbox" className="form-check-input" id={id} name={id} required={required === true} value={o.id} autoFocus={index ===1? true: false } checked={false} onChange={(e) => {onAnswer(id, e.target.value)}} />
                                    }                                    
                                    {jsonUnescape(o.text)}
                                </label>
                            </div>              
                          ))
                      }
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireMultiCheckbox;