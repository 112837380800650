import React from 'react';
import styles from './footer.module.scss';
import {configuration} from '../../services/Checkout.settings.service'


const Footer = () => {
    var d = new Date();
    return ( 
        <div>
            {
                configuration.embedded === false && <footer className={`${styles.footer}  mt-5 p-3`}>
                2015 - {d.getFullYear()} Powered by reservie.net
                </footer>
            }
        </div>
        
     );
}
 
export default Footer;