

export const CheckoutControllerReducer = (state, action) => {

    console.log('action', action, state);

    
    switch (action.type) {

        case "SET_STAGE":
        state.stage = action.payload; 
        return {
            ...state,
        } 
 

        default:
            return state

    }
}