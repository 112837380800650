

export const JwtReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_JWT":
        {
                state.jwt = action.payload;
                return {
                    ...state,
                }                 
        }


        default:
            return state

    }
}