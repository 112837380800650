import React from 'react';
import Header from './shared/header';
import Footer from './shared/footer';
import styles from './Layout.module.scss';
import { Helmet } from 'react-helmet-async';
import 'bootswatch/dist/lux/bootstrap.css'

const Layout = ({title, description, children, layout}) => {
    return ( 
        <>
        <Helmet>
            <title>{ title ? title + " - reservie booking system": "reservie booking system"}</title>
            <meta name = "description" content={ description || "reservie booking system" } />
        </Helmet>
        <Header layout={layout}/>
            <div className={styles.p__rVieWrapper}>    
                <div className="container-fluid">
                    {children}
                </div>
            </div>

        <Footer/>
        </>
     );
}
 
export default Layout;