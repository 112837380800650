import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import NotFound from '../pages/NotFound';
import CheckoutController from '../controllers/CheckoutController';


const Routes = () => {
  return (
    <Router>
        <Switch>
          <Route path="/login-proxy" render={(props) => (<CheckoutController {...props} mode="login-proxy"/>)} />
          <Route path="/waitlist-proxy" render={(props) => (<CheckoutController {...props} mode="waitlist-proxy"/>)} />
          <Route path='/website' component={() => { 
                window.top.location.href  = window.hostwebsite; 
                return null;
          }}/>    
          <Route path='/account' component={() => { 
                window.top.location.href  = window.account; 
                return null;
          }}/>                           
          <Route path="/" render={(props) => (<CheckoutController {...props} mode="proxy" />)} />
          <Route path="*" component={NotFound} />
        </Switch>
    </Router>
  );
}

export default Routes;